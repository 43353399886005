.body {
    padding: 0rem 0rem 0 0rem;
}

.buttons {
    
    display: flex;  flex-direction: row;justify-content: space-between;
    padding: 1rem;
}

.button {
    grid-area: button;
    margin-right: 1rem;
}

.savebutton {
    border-radius: 10px;
    margin-bottom: 0;
    background-color:  var(--primary);
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    color: white;
}
.savebutton:hover {
    cursor: pointer;
    background-color:  var(--hover);
    color: white;
}

.header {
    background: var(--light);
    display: flex; justify-content: space-between;
    min-height: 2rem;
    padding: 0 .5rem;
}

.heading {
    margin: .5rem .5rem .5rem 0;
}

.overlay {
    background-color: rgba(0,0,0,0.5);
    display: flex; justify-content: center;
    position: fixed; top: 0; left: 0;
    height: 100%; width: 100%;
    z-index: 1;
}

.wrapper {
    background: white;
    border: var(--border);
    border-radius: var(--radius);
    display: flex; flex-direction: column; justify-content: space-between;
    /*min-width: 30rem;*/
    position: absolute;
}

.ontop{
    z-index: 400;
    position:absolute;
}


.x {
    cursor: pointer;
    font-size: var(--xxl);
}


.column {
    min-width: 15rem;
    padding: 1rem;
    padding-bottom: 0rem;
    padding-top: 0rem;
}

.bottombutton{
    margin-left:auto;
    padding: 1rem;
}
