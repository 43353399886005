.avatar {
    flex-shrink: 0;
    grid-area: avatar;
    height: 2.25rem;
    width: 2.25rem;
}

.bottom {
    margin: 0 0 .5rem 1rem;
}

.container {
    background-color: var(--light);
    flex-shrink: 0;
    width: 10rem;
    position: sticky; top: 0;
}

.logo {
    margin-bottom: 1.5rem;
    max-width: 1.5rem;
}

.logOut {
    cursor: pointer;
    color: var(--purple);
    font-size: var(--xs);
    grid-area: logout;
    margin-top: .0rem;
    padding: 0;
    border:0;
    background:none;
    text-align: left;
}
.logOut:hover {
    color: var(--hover);
}

.menu {
    display: flex; flex-direction: column;
}

.menuItem {
    color: var(--dark);
    font-size: var(--m);
    line-height: 2rem;
}
.menuItem:hover, .menuItemActive {
    color: var(--primary) !important;
}

.sticky {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    position: sticky;
    top: 0;
}

.top {
    margin: 1.5rem 0 0 2rem;
}

.userCompany {
    color: var(--dark);
    font-size: var(--xs);
    grid-area: company;
    overflow: hidden; text-overflow: ellipsis;
}

.userContainer {
    display: grid;
    grid:
        "avatar name"
        "avatar company"
        ". logout"
        / auto 1fr;
    gap: .25rem;
    margin: 1rem .1rem .25rem 0;
}

.userFullName {
    color: black;
    font-size: var(--s);
    grid-area: name;
    overflow: hidden; text-overflow: ellipsis;
}

.choice {
    display: inline-flex;
    margin: 4px 0;
}

.radioContainer {
    font-size: medium;
}

.radioContainer input[type=radio]{
    margin: 0 0 0;
    vertical-align: middle;
    transform: translate(-2px,0px);
    box-shadow: inset 0 0 0 .2rem white;
    height: 1rem;
    width: 1rem;
    padding: 0;
    flex-shrink: 0;
    /*transform: scale(0.5, 0.5);*/
}

.radioContainer label{
    /*flex-shrink: 1;*/
    /*text-transform: capitalize;*/
    font-size:var(--m);
    font-weight: normal;
    color: var(--dark);
    /*overflow: hidden;*/
    text-overflow: ellipsis;
}

.radioContainer label:hover{
    color: var(--primary) !important;
}