.checkboxCell {
    display: flex;
    align-items: center;
}

.tableFull {
    align-self: stretch;
    
}
.summaryRow{
    align-self: stretch;
}
.content{
    display: flex;
    flex-direction: column;
    align-self: stretch;
    height: 100%;
    overflow:auto;
    margin-top: -0.5em;
}
.tableWithoutCheckbox {
    align-self: stretch;
}
.tableWithoutState {
    align-self: stretch;
}
.tableBodyScrollable {
    align-self: stretch;
    table-layout: fixed;
    max-height:100%;
    justify-content: start; 
}

.stretch {
    flex: 1; 
    }