.footer {
    background: var(--light);
    box-sizing: border-box;
    padding: .75rem 1.5rem;
    width: 100%;
}

.pipe {
    margin: 0 .75rem;
}

.registered {
    margin-right: .25rem;
}

.text {
    color: var(--dark);
    font-size: var(--xs);
    text-align: right;
}