.columns {
    display: grid; gap: .25rem 2rem;
}

.two {
    composes: columns;
}
@media (min-width: 45rem) {
    .two {
        grid-template-columns: repeat(2, 1fr);
    }
}

.three {
    composes: columns;
}
@media (min-width: 768px) {
    .three {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 425px) and (max-width: 767px) {
    .three {
        grid-template-columns: repeat(2, 1fr);
    }
}

.four {
    composes: columns;
}
@media (min-width: 768px) {
    .four {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media (min-width: 425px) and (max-width: 767px) {
    .four {
        grid-template-columns: repeat(2, 1fr);
    }
}
