.asterisk {
    content: '*';
    color: var(--red);
    font-size: 0.5rem;
    margin-left: .25rem;
}

.error {
    color: var(--primary);
    font-size: .75rem;
    font-weight: 300;
}

.errorPlaceholder {
    margin-top: .25rem;
    min-height: 1rem;
}

.hidden {
    visibility: hidden;
}

.itemcell{
    color: var(--dark);
    font-size: var(--xs);
    padding: 0px;
    margin:0px;
}

.formikcell{
    border: 0px solid white;
    margin-bottom: -10px;
    margin-top: -10px;
    padding: 0px;
    font-size: var(--xs);
    border-bottom: 0px solid var(--semilight);
    outline: none;
}


.formikcheckbox{
    border: 0px solid white;
    height: 0.8rem;
    width: 1.6rem;
    vertical-align: middle;
    margin-bottom: -10px;
    margin-top: -10px;
    padding: 0px;
    font-size: var(--xs);
    border-bottom: 0px solid var(--semilight);
    outline:  1px solid var(--semilight);;
    
    
}