.container {
    display: flex;
    min-height: 100vh;
}

.content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.main {
    box-sizing: border-box;
    flex-grow: 1;
    padding: 1rem;
}