.captureIcon {
    animation: rotation 10s infinite linear;
    fill: var(--primary);
    width: 1.25rem;
}

.checkboxCell {
    display: flex;
    align-items: center;
}

.dots {
    color: var(--primary);
    font-size: 1rem;
    font-weight: 900;
}

.focusedRow > td {
    background-color: var(--light);
}
.focusedRowActive > td {
    background-color: var(--light);
    color: var(--purple);
}

.hidden {
    display: none;
}

.optionsCell {
    cursor: pointer;
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding-right: .25rem;
    position: relative;
}

.optionsList {
    position: absolute;
    background-color: white;
    box-shadow: 0 0 .75rem var(--semilight);
    padding: .5rem 0;
    position: absolute;
    top: 1.05rem;
    right: 0;
    white-space: nowrap;
    z-index: 1;
    
}

.optionsListReversed {
    background-color: white;
    box-shadow: 0 0 .75rem var(--semilight);
    padding: .5rem 0;
    position: absolute;
    bottom: 1.05rem;
    right: 0;
    white-space: nowrap;
    z-index: 1;
}

.optionsListIcon {
    fill: var(--primary);
    margin-right: .4rem;
    width: .95rem;
}

.exportIcon {
    fill: var(--primary);
    margin-right: .4rem;
    width: .95rem;
    rotate: 270deg;
}

.archiveIcon {
    fill: var(--primary);
    margin-right: .4rem;
    width: .95rem;
}

.optionsListCancelIcon {
    composes: optionsListIcon;
    padding: 0 .15rem;
    width: .65rem;
}

.optionsListItem {
    cursor: pointer;
    display: flex; align-items: center;
    padding: .14rem .55rem;
}

.optionsListItem:hover {
    background-color: var(--light);
}

.optionsListText {
    color: var(--dark);
}

.optionsTrigger {
    align-items: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    text-align: center;
    min-height: 1.5rem;
    min-width: 1.5rem;
    user-select: none;
}

.optionsTriggerActive {
    composes: optionsTrigger;
    background: white;
}

.textCell {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: .5rem;
    text-align: right;
}
.by {
    composes: textCell;
    color: var(--primary);
}
.greyText {
    composes: textCell;
    color: var(--dark);
}
.inactive {
    composes: textCell;
    color: var(--semidark);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}