.buttons {
    display: flex;
    padding-top: 1rem;
}
.multipleButtons {
    composes: buttons;
    justify-content: space-between;
}
.singleButton {
    composes: buttons;
    justify-content: flex-end;
}

.cancelIcon {
    cursor: pointer;
    fill: var(--primary);
    height: 1rem;
    width: var(--xs);
}

.topRow {
    display: flex; justify-content: flex-end;
}

.wrapper {
    background: var(--light);
    border: var(--border);
    margin-bottom: 1rem;
    padding: .5rem .5rem 0 .5rem;
}