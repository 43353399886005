.button {
    background-color: transparent;
    border: 1px solid var(--semilight);
    color: var(--semidark);
    margin: 0;
    padding: .3rem 1rem;
}
.button:hover {
    background-color: var(--light);
    border-color: var(--semidark);
    color: black;
}
.leftButton {
    composes: button;
    border-radius: var(--radius) 0 0 var(--radius);
}
.leftButtonActive {
    composes: leftButton;
    background-color: var(--light);
    color: black;
}
.rightButton {
    composes: button;
    border-radius: 0 var(--radius) var(--radius) 0;
    border-left: none;
}
.rightButtonActive {
    composes: rightButton;
    background-color: var(--light);
    color: black;
}

.container {
    display: flex; align-items: center;
    grid-area: filterButtons;
    justify-self: end;
    margin-bottom: 1rem;
}