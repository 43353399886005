.actionButtons {
    display: flex; justify-content: flex-end;
}

.actionButton {

}

.prevNextCouple{
    display: flex; justify-content: flex-start;
}

.prevButton {
    margin-left: 1rem;
    margin-right: 5px;
}
.nextButton {
    margin-right: 1rem;
}

.secondaryButton {
    margin-right: 1rem;
}

.container {
    width:100%;
    height:100%;
}
@media (min-width: 1500px) {
    .container {
        grid-template-columns: 60% 38%;
    }
}



.heading {
    justify-self: flex-start;
    margin-right: 1rem;
    max-width: 40rem;
    overflow: hidden;
    text-overflow: ellipsis;
}




.wrap {
    max-width: 88vw;
}

.main {
    flex: 1 1 auto;
}

.menuItem {
    border-bottom: 3px solid transparent;
    color: var(--semidark);
    font-size: var(--s);
    font-weight: 700;
    margin: 0 1rem 1rem 0; padding-bottom: .75rem;
}
.menuItem:hover, .menuItemActive {
    border-color: var(--primary);
    color: black;
}

.navRow {
    display: flex; flex-wrap: wrap;
    margin-bottom: .5rem;
}

.secondaryButton {
    margin-right: 1rem;
}

.topRow {
    display: flex; align-items: flex-start; justify-content: space-between;
}

