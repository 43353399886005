.topRow {
    display: flex; justify-content: flex-start;
    color: var(--semidark);
    font-size: var(--s);
    margin: 0 0 0.2rem 0;
    font-weight: bold;
}
.cancelIcon {
    cursor: pointer;
    fill: var(--primary);
    height: 0.55rem;
    width: var(--xs);
}

.itemstable {
    background-color: white;
    border-collapse: collapse;
    border: 1px solid var(--semilight);
    grid-template-columns: 2rem 1fr 0.38fr 0.58fr 0.22fr 0.4fr 0.32fr 0.32fr 0.43fr 0.43fr;
    text-align: center;
    justify-content: auto;
    line-height: 0.6;
    margin: 0 0 0.3rem 0;
    width:100%
   }

.nestedcell1{
    border: 0px;
    line-height: 0.5rem;
    width:5rem;
    border-right: 2px solid var(--semilight);
    text-align:center;
    margin:0;
}
.nestedcell2{
    border: 0px;
    line-height: 0.6;
    width:15rem;
    margin:0;
}


.acrosstwocolumns{
    grid-column: span 2; 
    justify-content: center;
    border: 0.5px solid var(--semilight);
    padding:0;   
    margin:0;
}

.acrosstwocolumnsbottom{
    grid-column: span 2; 
    justify-content: center;
    border: 0.5px solid var(--semilight);
    
}



.acrosstwocolumnsbottom1{
    grid-column: span 2; 
    height: 1.1rem; /* Or set this to `auto` if you want it to inherit from the parent */
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid var(--semilight);
    background-color: white;
    box-sizing: border-box;
    overflow: hidden; /* Prevent content overflow */
    flex: 1; /* Allow the element to grow to fit its parent */
    }

.itemstd{
    height:0.5rem;
    justify-content: center;
    border: 0.5px solid var(--semilight);
    background-color: white;
    
}

.itemstd1 {
    height: 1.1rem; /* Or set this to `auto` if you want it to inherit from the parent */
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid var(--semilight);
    background-color: white;
    box-sizing: border-box;
    overflow: hidden; /* Prevent content overflow */
    flex: 1; /* Allow the element to grow to fit its parent */
}


.checkcell{
    height:0.5rem;
    border: 0.5px solid var(--semidark);
    background-color: white;
    align-items: center;       /* Center content vertically */
    justify-content: center;   /* Center content horizontally */
}

.acrosstwocolumnshighlighted{
    grid-column: span 2; 
    justify-content: center;
    border: 0.5px solid var(--dark);
}


.acrosstworows{
    grid-row: span 2; 
    justify-content: center;
    border: 1px solid var(--semilight);
}

.itemstableheader{
    background-color: var(--light);
    text-align: center;
    border: 1px solid var(--semilight);
    
}

.itemstablebody{
    background : var(--light);
    text-align: center;
    border-collapse:  collapse;
    
}

.rowwrapper {
    display: flex;
    width:100%;
    flex-direction: row; 
    gap: 5px; 
}

.fixedsizecheckbox {
    height: 0.2rem,  !important;
}