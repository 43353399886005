.pageNumber {
    border: none;
    font-family: var(--font);
    font-size: var(--l);
    height: 1.75rem;
    width: 1.75rem;
}

.button {
    composes: pageNumber;
    background: none;
    color: var(--semidark);
    fill: var(--semidark);

}

.button:disabled {
    color: var(--semilight);
    cursor: default;
    fill: var(--semilight);
}

.button:not(:disabled):hover {
    color: var(--dark);
    fill: var(--dark);
}

.current {
    composes: pageNumber;
    background: rgba(157, 59, 138, .25);
    border-radius: var(--radius);
}

.current:hover {
    cursor: default;
}

.icon {
    width: var(--s);
}

.rightIcon {
    composes: icon;
    margin-left: -.4rem;
}

.pagination {
    flex-grow: 1;
    text-align: center;

}

.wrapper {
    display: flex; 
    align-items: center;
    justify-content: space-between;
}



.paginationDiv {
    display: flex;
    align-items: left;
    margin-right: auto;
}

.paginationPar {
    white-space: nowrap;      
    overflow: hidden;         
    text-overflow: ellipsis;  
    margin-left:0.5em;
}
.paginationPager {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (max-width: 1700px) {
    .paginationPager {
        flex: 0 0 40%;
    }
}
@media (max-width: 1100px) {
    .paginationPager {
        flex: 0 0 45%;
    }
}
@media (max-width: 728px) {
    .paginationDiv {
        display: block;
    }
}

.paginator {
    margin-left: 1rem;
    width: auto;
    border-bottom: none;
    border: 1px solid var(--semilight);
    padding: 0.5rem 1rem 0.5rem 0.56rem;
}