.form {
    display: flex; flex-direction: column;
}

.heading {
    margin-top: 20vh;
}

.info {
    color: var(--dark);
    margin-bottom: 1rem;
}

.link {
    display: block;
    margin-top: .5rem;
}