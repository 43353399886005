.button {
    align-self: center;
    justify-self: right;
}

.container {
    display: flex; flex-direction: column;
}

.total {
    color: var(--dark);
    font-weight: 700;
    grid-column-start: 2;
    justify-self: right;
    margin: 0 0 1rem 0;
}

.rounding {
    flex-direction: column;
    color: var(--dark);
    font-weight: 700;
    grid-column-start: 4;
    justify-self: right;
    margin: 0 0 1rem 0;
}
