.container {
    border: 1px solid var(--semilight);
    border-radius: 3px;
    display: flex; align-items: center;
    grid-area: searchBar;
    margin-bottom: 1rem;
    padding: .29rem;
}

.icon {
    fill: var(--semidark);
    height: 1rem;
    margin: 0 .4rem;
}

.input {
    border: none;
    padding: 0;
    min-width: 17rem;
}
.input:focus {
    border: none;
}