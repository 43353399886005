
.textCell {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: .5rem;
    text-align: right;
    color: var(--semidark);
}

.firstCell {
    cursor: pointer;
    overflow: visible;
    white-space: normal;
    padding-top: .5rem;
    text-align: left;
    color: var(--semidark);
}

