.heading {
    color: var(--semidark);
    font-size: var(--s);
    font-weight: bold;
    margin-bottom: .5rem;
}

.inputs {
    display: flex;
    margin-bottom: 1rem;
}