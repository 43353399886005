.sticky{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
}

.bulkModifyButton {
    justify-self: right;
    grid-column: 1;
    margin-right: 0.2em
}

.exportButton {
    justify-self: right;
    grid-column: 2;
    margin-right: 0.2em
}


.exportButtonArchive {
    justify-self: right;
    grid-column: 3;
}

.captureButton {
    justify-self: right;
    grid-column: 3;
    margin-right: 0.2em
}

.actionButton {
    justify-self: right;
    grid-column: 4;
}


.buttons {
    display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: right;
    margin: .5rem 0;
    justify-self: end;
    }

.buttonsArchive {
    display: grid; grid-template-columns: 1fr 1fr 1fr;
    align-items: right;
    margin: .5rem 0;
    justify-self: end;
    }

.footer {
    width: 100%;
}

.footerArchive {
    display: grid; grid-template-columns: 3fr  1fr 1fr ;
    align-items: right;
    margin: .5rem 0;
    width: 100%;
}


.footerNarrow {
    display: flex;
    flex-direction: column;
}
.buttonsNarrow {
    display: grid; grid-template-columns:  1fr 1fr 1fr ;
    align-items: right;
    margin: .5rem 0;
    justify-self: end;
    grid-column: 2;
}
.buttonsNarrowArchive {
    display: grid; grid-template-columns: 1fr 1fr 1fr ;
    align-items: right;
    margin: .5rem 0;
    justify-self: end;
    grid-column: 2;
}
.footerNarrowArchive {
    display: grid;
    grid-template-columns:  1fr 1fr;
    grid-template-rows: auto auto;
    align-items: right;
    margin: .5rem 0;
    width: 100%;
}


.topRowButtons {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr repeat(4, auto);
    justify-content: flex-start;
    gap: 0em;
}



.leftButtons {
    grid-column: 1; 
    display: grid;
    grid-template-columns: auto auto auto;  
    gap:5px;
    width :100%;
    justify-content: flex-start;
  }

  
.rightButtons {
    display: flex;
  justify-content: flex-end;
  gap: 5px; 
  grid-column: 3 / span 4; 
  }

  .leftButtons button {
    height: 2.0em; 
    display: flex;
    justify-content: center; 
    align-items: center; 
    padding: 0 0.5em; 
  }
  .rightButtons button {
    height: 2.0em; 
    display: flex;
    justify-content: center; 
    align-items: center; 
    padding: 0 0.5em; 
  }

  .customButton {
    height: 1em !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0 0.5em !important;
  }
  

.heading {
    grid-area: heading;
}

.wrap{
   max-width: 90vw;
}

.main {
    /* display: grid; align-items: start; gap: 1rem; grid-template-columns: auto; */
}
/*
@media (min-width: 1024px) {
    .main {
        grid-template-columns: auto 22.25vw;
    }
}
*/
.tableContainer {
    display: flex; align-items: flex-end; flex-direction: column; height:72vh
}

.topRow {
    margin-bottom: -0.5em;
    display: grid; align-items: start; gap: 0 1rem;
    grid:
        "heading filterButtons"
        "searchBar searchBar"
        / 1fr 1fr;
}
@media (min-width: 768px) {
    .topRow {
        grid:
            "heading searchBar filterButtons"
            / 1fr 1fr 1fr;
    }
}