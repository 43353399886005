.placeholderText {
    color: var(--purple);
    font-size: 1rem;
    font-weight: 700;
    margin-top: 10rem;
    opacity: .4;
    text-align: center;
}
.textAnnotation img{
    display: none;
}