.body {
    padding: 1rem 1rem 0 1rem;
    min-width: 200px;
}

.buttons {
    display: flex; justify-content: space-between;
    min-width: 12rem;
    padding: 1rem;
}

.buttonsCapture {
    display: flex; justify-content: space-between;
    min-width: 12rem;
    padding: 1rem;
}

.buttonsingle {
    text-align: right;
    padding: 1rem;
}

.header {
    background: var(--light);
    display: flex; justify-content: space-between;
    min-height: 2rem;
    padding: 0 .5rem;
}

.heading {
    margin: .5rem .5rem .5rem 0;
}

.infotablebody{
    grid-template-columns: 50% 50%;
    width: 500px;
    text-align: left;
}

.infohead{
    font-weight: bold;
    color: var(--dark)
}

.overlay {
    background-color: rgba(0,0,0,0.5);
    display: flex; justify-content: center;
    position: fixed; top: 0; left: 0;
    height: 100%; width: 100%;
    z-index: 1;
}

.wrapper {
    background: white;
    border: var(--border);
    border-radius: var(--radius);
    display: flex; flex-direction: column; justify-content: space-between;
    /*min-width: 22rem;*/
    position: absolute;
    z-index: 50;
}

.ontop{
    z-index: 400;
    position:absolute;
}
.large {
    composes: wrapper;
    top: 1rem; bottom: 1rem;
    width: 80vw;
}
.small {
    composes: wrapper;
    top: 10%;
    max-height: 90%;
}
.itemsEdit {
    composes: wrapper;
    top: 10rem;
    left: 10rem;
}

.x {
    cursor: pointer;
    font-size: var(--xxl);
}

.scroll {
    overflow-y: auto;
}

.columnDiv {
    display: flex;
    align-content: stretch;
    padding: 10px;
}

.columnDiv select {
    margin-right: 10px;
}

.columnDiv button {
    flex-grow: 1;
}

.columnDiv button + button {
    margin-left: 10px;
}

.buttonleft {
    margin: 0.3rem;
    margin-top: 0;
    height: 2em;
    padding: 0 0.5em; /* Adjust padding as needed */
    width: auto; /* Width adjusts to fit text */
    display: inline-block; /* Makes the button width fit its content */
    text-align: center; /* Centers text inside the button */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

.buttonright {
    margin: 0.3rem;
    margin-top: 0;
    height: 2em;
    padding: 0 0.5em; /* Adjust padding as needed */
    width: auto; /* Width adjusts to fit text */
    display: inline-block; /* Makes the button width fit its content */
    text-align: center; /* Centers text inside the button */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}