.avatarContainer {
    flex-shrink: 0;
    font-size: 1.75rem;
    height: 4rem;
    margin-right: 1rem;
    width: 4rem;
}

.avatarUpload {
    cursor: pointer;
    display: flex; align-items: center;
    grid-row: 1;
    margin-bottom: 1rem;
}

.avatarUploadText {
    color: var(--primary);
    font-weight: 300;
}
.avatarUploadText:hover {
    color: var(--hover);
}

.editIcon {
    fill: var(--primary);
    height: .9rem;
    margin-right: .25rem;
}
.editIcon:hover {
    fill: var(--hover);
}

.form {
    margin-bottom: 1.5rem;
}