.pdfContainer {
    flex-grow: 2;
    overflow: auto;
    width: 80vw;
   
}

.icons {
    align-items:center;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.rotateIcon {
    margin: 0.5rem;
    width: var(--xl);
    cursor: pointer;
    fill: var(--semidark);
}

.rotateIcon:hover {
    fill: var(--dark);
}

.zoomIcon {
    font-size: var(--xxl);
    padding: 0 .25rem;
    width: var(--s);
}
.zoomIconInactive {
    composes: zoomIcon;
    fill: var(--semilight);
}
.zoomIconActive {
    composes: zoomIcon;
    fill: var(--semidark);
    cursor: pointer;
}
.zoomIconActive:hover {
    fill: var(--dark);
}

.onTop{
    z-index: 450;
    position:absolute;
}