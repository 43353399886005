.asterisk {
    content: '*';
    color: var(--red);
    font-size: var(--xs);
    margin-left: .25rem;
}

.error {
    color: var(--primary);
    font-size: .75rem;
    font-weight: 300;
}

.errorPlaceholder {
    margin-top: .25rem;
    min-height: 1rem;
}

.hidden {
    visibility: hidden;
}