.container {
    position: relative;
}

.list {
    background: white;
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
}
.hiddenList {
    composes: list;
    border: none;
}
.visibleList {
    composes: list;
    border: var(--border);
    border-top: none;
    max-height: 500px;
    max-width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    white-space: nowrap;     /* Prevents text from wrapping to a new line */
    width: fit-content;
}

.item {
    color: var(--dark);
    font-size: var(--s);
    font-weight: 400;
    padding: .5rem;
}

.item:hover {
    background-color: var(--light);
    color: black;
}

.highlight {
    background-color: var(--light);
    color: black !important;
}