.box {
    display: flex;
    justify-content: center;
}
.leftBox {
    composes: box;
    flex: 45;
}
.rightBox {
    composes: box;
    background-image: url("../assets/jpg/welcome.jpg");
    background-size: cover;
    background-position: right;
    box-shadow: inset 0 0 0 100vh rgba(35, 37, 66, 0.75);
    flex: 55;
}

.container {
    display: flex; flex-wrap: wrap;
    min-height: 100vh;
}

.form {
    display: flex; flex-direction: column;
    margin: 1rem;
    width: 14rem;
}

.logo {
    margin-top: 20vh;
    max-width: 2rem;
}

.welcome {
    margin: 0 32% 0 10%;
    padding-left: 3rem;
}

.welcomeHeading {
    color: white;
    line-height: 2.5rem;
    text-transform: uppercase;
}
.welcomeHeadingBottom {
    composes: welcomeHeading;
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 1.75rem;
}
.welcomeHeadingTop {
    composes: welcomeHeading;
    font-size: 2.75rem;
    margin: 3rem 0 0 0;
}

.welcomeText {
    color: var(--semilight)
}
