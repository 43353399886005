.container {
    background: white;
    border: 1px solid var(--primary);
    border-radius: var(--radius);
    color: var(--primary);
    position: fixed;
    /*top: 2rem;*/
    font-size: calc(var(--m) + 3px);
    top: 50%;
    left: 50%;
    margin-right: -50%;
    margin-bottom: -50%;
    transform: translate(-50%, -50%);
    padding: 2rem;
    z-index: 11111;
}

.hidden {
    composes: container;
    opacity: 0;
    pointer-events: none;
    transition: opacity .5s ease-out;
}

.visible {
    composes: container;
    transition: opacity .5s ease-in;
}

.list {
    color: var(--dark);
    font-weight: bold;
    margin: .5rem 0 0 .5rem;
    line-height: 1.5rem;
    list-style: none;
}