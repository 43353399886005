.button {
    border: 1px solid;
    border-color: var(--primary);
    border-radius: var(--radius);
    padding: .5rem 1.5rem;
}
.primary {
    composes: button;
    background-color: var(--primary);
    color: white;
}
.primary:disabled {
    background-color: var(--light);
    border-color: var(--semidark);
    cursor: default;
    color: var(--primary);
}

.primary:hover:not(:disabled) {
    background-color: var(--hover);
}

.wideprimary {
    composes: button;
    background-color: var(--primary);
    color: white;
    width:100%;
}
.wideprimary:disabled {
    background-color: var(--light);
    border-color: var(--semidark);
    cursor: default;
    color: var(--primary);
}

.wideprimary:hover:not(:disabled) {
    background-color: var(--hover);
}


.secondary {
    composes: button;
    background: white;
    color: var(--primary);
}

.secondary:disabled {
    border-color: var(--semidark);
    color: var(--semidark);
    cursor: default;
}

.secondary:hover:not(:disabled) {
    color: var(--hover);
}
