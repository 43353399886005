.expandableRow {
    cursor: pointer;
}

.expandableHighlightedRow {
    cursor: pointer;
    font-weight: 700;
}

.greyText {
    color: var(--dark);
}

.highlightedRow {
    font-weight: 700;
}

.iconCell {
    display: flex; align-items: center;
}

.toggleDetailIcon {
    cursor: pointer;
    fill: var(--primary);
    width: var(--s);
}
.toggleDetailIcon:hover {
    fill: var(--hover);
}

.icon {
    width: var(--l);
}
.defaultIcon {
    composes: icon;
    fill: var(--semidark);
}
.importedIcon {
    composes: icon;
    fill: var(--green);
}
.removedIcon {
    composes: icon;
    fill: var(--red);
}
.warningIcon {
    composes: icon;
    fill: var(--orange);
}