.buttons {
    display: flex; justify-content: space-between;
    margin-bottom: 1rem;
}

.cancelButton {
    justify-self: start;
}

.singleButton {
    display: flex; justify-content: flex-end;
}