.property {
    color: var(--semidark);
    font-size: var(--m);
    font-weight: bold;
    min-width: 12rem;
}

.value {
    font-size: var(--m);
}

.wrapper {
    border-bottom: var(--border);
    display: flex;
    margin-bottom: .5rem;
    padding: .25rem;
}