.icon {
    cursor: pointer;
    fill: var(--semidark);
    height: .65rem;
    margin-left: .25rem;
}
.icon:hover {
    fill: var(--dark);
}

.selectedtext {
    color: var(--purple)
}

.normaltext {
    color: var(--semidark)
}
.normaltext:hover {
    color: var(--dark)
}

.selectedicon {
    cursor: pointer;
    fill: var(--purple);
    height: .65rem;
    margin-left: .25rem;
}