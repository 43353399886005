.container {
    max-width: var(--form-max-width);
}

.input {
    border: none;
}
.input:disabled {
    background-color: white;
}
.firstNameInput {
    composes: input;
    flex-basis: 50%;
}

.lastNameInput {
    align-items: center;
    display: flex;
    flex-basis: 50%
}


