.chevronIcon {
    width: var(--s);
}
.chevronIconInactive {
    composes: chevronIcon;
    fill: var(--semilight);
}
.chevronIconActive {
    composes: chevronIcon;
    cursor: pointer;
    fill: var(--semidark);
}
.chevronIconActive:hover {
    fill: var(--dark);
}

.container {
    border: var(--border);
    
}

.icons {
    align-items:center;
    display: flex;
    justify-content: space-around;
    width: 35%;
}


.options {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--light);
    padding: .25rem .5rem;
}

.optionsRow{
    width: 100%;
    display: inline-flex;
    justify-content: space-around;
}

.page {
    display: table; /* enables selecting text when the document is zoomed in */
}

.pagesContainer {
    display: flex; align-items: center;
    justify-self: start;
}

.pagesText {
    font-size: var(--s);
    margin: 0 .25rem;
    user-select: none;
}

.placeholder {
    display: flex; flex-direction: column;
    overflow: auto;
}

.placeholderText {
    color: var(--purple);
    font-size: 1rem;
    font-weight: 700;
    margin: 125px 0 350px 0;
    opacity: .4;
}

.select {
    background-size: .85rem !important;
    flex-grow: 3;
    padding: .25rem .5rem .25rem .25rem;
}

.removeIcon {
    margin-right: .25rem;
    width: var(--m);
}
.removeIconInactive {
    composes: removeIcon;
    fill: var(--semilight);
}
.removeIconActive {
    composes: removeIcon;
    cursor: pointer;
    fill: var(--semidark);
}
.removeIconActive:hover {
    fill: var(--dark);
}

.uploadIcon {
    justify-self: end;
    width: var(--xxl);
}
.uploadIconInactive {
    composes: uploadIcon;
    fill: var(--semilight);
}
.uploadIconActive {
    composes: uploadIcon;
    cursor: pointer;
    fill: var(--semidark);
}
.uploadIconActive:hover {
    fill: var(--dark);
}

.viewIcon {
    margin: 0 .5rem;
    width: var(--xxl);
}

.rotateIcon {
    margin: 0.5rem 0.1rem;
    width: var(--xl);
    cursor: pointer;
    fill: var(--semidark);
}

.rotateIcon:hover {
    fill: var(--dark);
}

.viewIconInactive {
    composes: viewIcon;
    fill: var(--semilight);
}
.viewIconActive {
    composes: viewIcon;
    cursor: pointer;
    fill: var(--semidark);
}
.viewIconActive:hover {
    fill: var(--dark);
}

.zoomIcon {
    font-size: var(--xxl);
    padding: 0 .25rem;
    width: var(--s);
}
.zoomIconInactive {
    composes: zoomIcon;
    fill: var(--semilight);
}
.zoomIconActive {
    composes: zoomIcon;
    fill: var(--semidark);
    cursor: pointer;
}
.zoomIconActive:hover {
    fill: var(--dark);
}