.row {
    background: var(--light);
    border-left: var(--border); border-right: var(--border);
    display: grid;
    grid-column: 1 / 6;
    padding: 0 .5rem;
}
.header {
    composes: row;
    color: var(--primary);
    font-weight: 700;
    padding-top: .25rem;
}
.bottom {
    composes: row;
    border-bottom: var(--border);
    padding-bottom: .25rem;
}
.changeHeader {
    composes: header;
    grid-template-columns: 10rem 1fr 1fr;
}
.changeRow {
    composes: row;
    grid-template-columns: 10rem 1fr 1fr;
}
.changeBottom {
    composes: changeRow;
    border-bottom: var(--border);
    padding-bottom: .25rem;
}
.costsHeader {
    composes: header;
    grid-template-columns: 1.5rem 1fr 1fr 1fr 1fr;
}
.costsHeaderShort {
    composes: header;
    grid-template-columns: 1.5rem 1fr 1fr 1fr;
}
.costsRow {
    composes: row;
    grid-template-columns: 1.5rem 1fr 1fr 1fr 1fr ;
}
.costsRowShort {
    composes: row;
    grid-template-columns: 1.5rem 1fr 1fr 1fr ;
}
.costsBottom {
    composes: costsRow;
    border-bottom: var(--border);
    padding-bottom: .25rem;
}
.costsBottomShort {
    composes: costsRowShort;
    border-bottom: var(--border);
    padding-bottom: .25rem;
}
.row > td {
    border: none;
}

.greyText {
    color: var(--dark);
}

.arrowIcon {
    fill: var(--dark);
    transform: rotate(90deg);
}

.costValue {
    color: var(--dark);
    text-align: center;
}
.costValueChanged {
    color: var(--dark);
    display: grid;
    grid-template-columns: 1fr 1rem 1fr;
    justify-items: center;
}
