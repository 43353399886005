.cancelIcon {
    cursor: pointer;
    fill: var(--primary);
    height: .5rem;
}

.checkIcon {
    fill: var(--green);
    height: .75rem;
}

.dropzone {
    border: 2px dashed var(--light);
}

.dropzoneBox {
    flex: 1;
}

.dropzoneCaption {
    color: var(--dark);
    font-size: var(--m);
    font-weight: 300;
    margin: 0 1rem;
}

.fileName {
    font-size: var(--s);
    flex-basis: 65%;
}
.fileNameDone {
    composes: fileName;
    color: black;
}
.fileNameDefault {
    composes: fileName;
    color: var(--dark);
}

.fileSize {
    color: var(--dark);
    font-size: var(--s);
    flex-basis: 15%;
}

.icon {
    height: 1.25rem;
    text-align: right;
    width: 1rem;
}
.fileIcon {
    composes: icon;
    fill: var(--dark);
}
.editIcon {
    composes: icon;
    fill: var(--dark);
}
.errorIcon {
    composes: icon;
    color: var(--red);
    font-size: var(--xl);
    font-weight: bold;
}

.errorMessage {
    color: var(--red);
    font-size: var(--s);
}

.hint {
    background-color: var(--light);
    display: flex;
    justify-content: center;
    margin: 1.5rem 0;
}

.hintSymbol {
    color: var(--primary);
    font-weight: 700;
    margin: .45rem .25rem .55rem 0;
}

.hintText {
    color: var(--semidark);
    font-size: .8rem;
    margin: .5rem 0;
}

.inputLabel {
    cursor: pointer;
    display: flex; flex-direction: column; justify-content: center;
    min-height: 50vh;
    font-family: inherit;
    text-align: center;
}

.previewContainer {
    display: flex;
}

.progressBox {
    flex: 1;
}

.progressContainer {
    flex: 1;
    margin: 0 0 2rem .5rem;
}

.progressPlaceholder {
    height: .25rem;
}

.progressRow {
    display: flex;
    justify-content: space-between;
    margin-top: .25rem;
}

.uploadIcon {
    fill: var(--purple);
    height: 5rem;
    margin-bottom: 2rem;
    opacity: .4;
}

.wrapper {
    display: flex; flex-direction: column;
}