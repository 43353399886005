.calendar {
    border-color: var(--semilight);
    border-radius: var(--radius);
    font-family: var(--font);
    font-size: var(--xs);
    font-weight: 300;
}

.calendar > button:nth-child(2) {
    border-right-color: var(--semidark);
}

.calendar > button:nth-child(2):hover {
    border-right-color: var(--dark);
}

.calendar > button:nth-child(3) {
    border-left-color: var(--semidark);
}

.calendar > button:nth-child(3):hover {
    border-left-color: var(--dark);
}

.calendar > div:first-child {
    border-bottom-color: var(--light) !important;
}

.calendar > div:first-child::before {
    border-bottom-color: var(--semilight) !important;
}

.calendar > div:last-child > div:first-child {
    background-color: var(--light);
    border-color: var(--semilight);
}

.container > div:first-child, .container > div:first-child > div:first-child {
    display: block;
}

.input {
    background: url('data:image/svg+xml;utf8, <svg version="1" fill="rgb(157, 59, 138)" xmlns="http://www.w3.org/2000/svg" viewBox="-48 -30 90 90"><path d="M35.8 3.7h-3.1V1.5c0-.7-.6-1.3-1.3-1.3-.7 0-1.3.6-1.3 1.3v2.2H9.9V1.5C9.9.8 9.3.2 8.6.2S7.2.8 7.2 1.5v2.2h-3c-2.2 0-4 1.8-4 4v28.2c0 2.2 1.8 4 4 4h31.7c2.2 0 4-1.8 4-4V7.7c-.1-2.2-1.9-4-4.1-4zm0 33.5H4.2c-.7 0-1.3-.6-1.3-1.3V15.2h34.3v20.7c0 .7-.6 1.3-1.4 1.3zM4.2 6.4h3.1v2.2c0 .7.6 1.3 1.3 1.3s1.3-.6 1.3-1.3V6.4h20.2v2.2c0 .7.6 1.3 1.3 1.3.7 0 1.3-.6 1.3-1.3V6.4h3.1c.7 0 1.3.6 1.3 1.3v4.8H2.8V7.7c0-.8.6-1.3 1.4-1.3z"/></svg>') no-repeat right;
}

.input:disabled {
    background: url('data:image/svg+xml;utf8, <svg version="1" fill="rgb(225, 223, 236)" xmlns="http://www.w3.org/2000/svg" viewBox="-48 -30 90 90"><path d="M35.8 3.7h-3.1V1.5c0-.7-.6-1.3-1.3-1.3-.7 0-1.3.6-1.3 1.3v2.2H9.9V1.5C9.9.8 9.3.2 8.6.2S7.2.8 7.2 1.5v2.2h-3c-2.2 0-4 1.8-4 4v28.2c0 2.2 1.8 4 4 4h31.7c2.2 0 4-1.8 4-4V7.7c-.1-2.2-1.9-4-4.1-4zm0 33.5H4.2c-.7 0-1.3-.6-1.3-1.3V15.2h34.3v20.7c0 .7-.6 1.3-1.4 1.3zM4.2 6.4h3.1v2.2c0 .7.6 1.3 1.3 1.3s1.3-.6 1.3-1.3V6.4h20.2v2.2c0 .7.6 1.3 1.3 1.3.7 0 1.3-.6 1.3-1.3V6.4h3.1c.7 0 1.3.6 1.3 1.3v4.8H2.8V7.7c0-.8.6-1.3 1.4-1.3z"/></svg>') no-repeat right;
}


:global .react-datepicker__day {
    border-radius: var(--radius) !important;
}

:global .react-datepicker__day:not(.react-datepicker__day--selected):hover {
    background-color: var(--light);
}

:global .react-datepicker__day--selected {
    background-color: var(--primary) !important;
}
