@import url('https://fonts.googleapis.com/css?family=Roboto:light|Roboto:bold|Roboto:black');

:root {
    --font: Roboto, sans-serif;
    --xs: .75rem;
    --s: .85rem;
    --m: .95rem;
    --l: 1.05rem;
    --xl: 1.15rem;
    --xxl: 1.5rem;
    --primary: rgb(157, 59, 138);
    --primarylight: rgb(235, 195, 227);
    --hover: rgb(147, 49, 128);
    --dark: rgb(121, 121, 131);
    --semidark: rgb(178, 176, 189);
    --semilight: rgb(225, 223, 236);
    --light: rgb(244, 245, 250);
    --purple: rgb(121, 69, 153);
    --border: 1px solid var(--semilight);
    --green: rgb(125, 209, 88);
    --red: rgb(234, 52, 51);
    --orange: rgb(245, 160, 54);
    --radius: 3px;
    --form-max-width: 44rem;
}

a {
    color: var(--primary);
    text-decoration: none;
}

body {
    font-family: var(--font);
    font-size: var(--m);
    margin: 0;
}

button {
    font-size: var(--s);
    outline: none;
    user-select: none;
    white-space: nowrap;
}

button:hover {
    cursor: pointer;
}

dd {
    display: inline-block;
    margin-left: .25rem;
}

dt {
    display: inline-block;
}

h1 {
    font-size: var(--xxl);
    font-weight: 300;
    margin: 0 0 1rem 0;
    white-space: nowrap;
}

h2 {
    font-size: var(--l);
    font-weight: 300;
    margin: 0 0 1rem 0;
    white-space: nowrap;
}

h3 {
    color: var(--semidark);
    display: block;
    font-size: var(--m);
    font-weight: bold;
    margin: 0 0 1.5rem 0;
}

html {
    font-size: calc(9px + .5vw);
}

input {
    background: none;
    border: none;
    border-bottom: var(--border);
    font-size: var(--s);
    line-height: 1.1rem;
    outline: none;
    padding: .5rem 0;
    width: 100%; min-width: 0;
}
:disabled {
    background: transparent;
    color: var(--dark);
}
[type="checkbox"] {
    -webkit-appearance: none;
    background: white;
    border: var(--border);
    border-radius: var(--radius);
    height: 1.25rem; width: 1.25rem;
    padding: 0; margin: 0 0;
}
[type="checkbox"]:checked {
    background: url('data:image/svg+xml;utf8, <svg version="1" fill="rgb(157, 59, 138)" xmlns="http://www.w3.org/2000/svg" viewBox="-10 -10 60 60"><path d="M39.7 7.6c0-1.1-.9-2-2-2-.6 0-1.1.2-1.4.6L12.9 29.5l-9.2-9.2c-.4-.4-.9-.6-1.4-.6-1.1 0-2 .9-2 2 0 .6.2 1.1.6 1.4l10.2 10.2c.3.6 1 1.1 1.8 1.1h.2c.8 0 1.4-.4 1.8-1.1L39.1 9c.3-.3.6-.8.6-1.4z"/></svg>') no-repeat center;
    background-color: white;
}
[type="checkbox"]:disabled {
    border-color: var(--light)
}
[type="number"] {
    -moz-appearance: textfield;
    background-color: transparent;
}
[type="password"] {
    color: var(--primary);
}
[type="number"]:active, [type="number"]:focus, [type="password"]:focus, [type="text"]:focus, select:focus {
    border-bottom: 1px solid var(--semidark);
}
[type="radio"] {
    appearance: none;
    border: var(--border);
    border-radius: 50%;
    box-shadow: inset 0 0 0 .25rem white;
    height: 1.25rem;
    margin: 0 .25rem;
    width: 1.25rem;
}
[type="radio"]:checked {
    background: var(--primary);
}
[type="radio"]:checked:disabled {
    background-color: var(--dark);
    opacity: .7;
}
[type="radio"]:focus {
    border-color: var(--semidark);
}
::placeholder {
    color: var(--dark);
    font-family: Roboto, sans-serif;
    font-size: var(--xs);
    font-weight: 300;
}
::-webkit-inner-spin-button {
    display: none;
}

label {
    color: var(--semidark);
    display: block;
    font-size: var(--s);
    font-weight: bold;
}

p {
    margin: 0;
}

progress {
    appearance: none; -moz-appearance: none; -webkit-appearance: none;
    background-color: var(--light);
    border: none;
    color: var(--primary);
    display: block;
    height: .25rem; width: 100%;
}
progress::-moz-progress-bar {
    background-color: var(--primary);
}
progress::-webkit-progress-bar {
    background-color: var(--light);
}
progress::-webkit-progress-value {
    background-color: var(--primary);
}

select {
    -webkit-appearance: none;
    background: url('data:image/svg+xml;utf8, <svg fill="rgb(157, 59, 138)" version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 -18 15 42"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.8 3.4L8.2 1.8 5 5.1 1.7 1.8.2 3.3l4.5 4.5.4.4z"/></svg>') no-repeat right;
    border: none;
    border-bottom: var(--border);
    border-radius: 0;
    font-size: var(--s);
    line-height: 1.1rem;
    outline: none;
    text-overflow: ellipsis;
    padding: .5rem .85rem .5rem 0;
    width: 100%;
}
select:disabled {
    background: url('data:image/svg+xml;utf8, <svg fill="rgb(225, 223, 236)" version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 -18 15 42"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.8 3.4L8.2 1.8 5 5.1 1.7 1.8.2 3.3l4.5 4.5.4.4z"/></svg>') no-repeat right;
    color: var(--dark);
}

table {
    display: grid;
    margin-bottom: .5rem;
}
tbody, thead {
    display: contents;
}
td {
    border-bottom: var(--border);
    padding: .25rem;
}
th {
    border-bottom: 1px solid var(--semilight);
    color: var(--semidark);
    display: flex; align-items: center;
    justify-content: flex-end;
    font-size: var(--xs);
    /*height: var(--xs);*/
    padding: .5rem .25rem;
}
tr {
    display: contents;
}

textarea {
    border: var(--border);
    border-radius: var(--radius);
    box-sizing: border-box;
    font-family: var(--font);
    font-size: var(--s);
    margin-top: .5rem;
    outline: none;
    padding: .25rem;
    width: 100%;
}

ul {
    padding-left: 0;
}