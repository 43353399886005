.body {
    padding: 0rem 1rem 0 1rem;
    display: flex;
   
}

.approvalDocuments{

}

.withoutApprovalDocuments{
    color:green
}

.buttons {
    display: flex; justify-content: space-between;
    padding: 0rem;
}

.header {
    background: var(--light);
    display: flex; justify-content: space-between;
    min-height: 2rem;
    padding: 0 .5rem;
}

.heading {
    margin: .5rem .5rem .5rem 0;
}

.overlay {
    background-color: rgba(0,0,0,0.5);
    display: flex; justify-content: center;
    position: fixed; top: 0; left: 0;
    height: 100%; width: 100%;
    z-index: 1;
}

.wrapper {
    background: white;
    border: var(--border);
    border-radius: var(--radius);
    display: flex; flex-direction: column; justify-content: space-between;
    /*min-width: 30rem;*/
    position: absolute;
}
.large {
    composes: wrapper;
    top: 1rem; bottom: 1rem;
    width: 80vw;
}
.small {
    composes: wrapper;
    top: 20%;
    max-height: 60%;
}

.x {
    cursor: pointer;
    font-size: var(--xxl);
}
.ontop{
    z-index: 500;
    position:absolute;
}


.docColumn {
    padding: 1rem;
    padding-bottom: 1rem;
    padding-top: 0rem;
    overflow-y: auto;
    overflow-x: auto;
    max-height: 10vh;
    max-width: 600px;
}

.columnL {
    text-align: left;
}

.column {
    text-align:  left;
    flex: 50%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
}

.columnManagers{
    overflow-y: auto;   
    max-height: 48vh;
}

.bottommessage {
    color: red;
    text-align:  center;
    flex: 50%;
    padding-top: 0rem;
    padding-bottom: 1rem;
    text-align: center;
}

.bottombutton{
    padding: 0.8rem !important;
}
.detailButton1{
    padding: 0.5rem !important;
    margin-bottom:0.5rem
}
.detailButton2{
    padding: 0.5rem !important;
    
}
.listButton1{
    padding: 1.0rem !important;
    margin-bottom: 0.5rem;
}
.listButton2{
    padding: 1.0rem !important;
    margin-bottom: 0.0rem;
}
