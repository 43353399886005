.circle {
    align-items: center;
    background-color: var(--purple);
    border-radius: 50%;
    display: flex;
    height: 100%;
    justify-content: center;
    opacity: 0.4;
    width: 100%;
}
.circle:hover {
    opacity: 1;
}

.initials {
    color: white;
    font-weight: 900;
}

.profilePicture {
    border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
}