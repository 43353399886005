.button {
    grid-area: button;
    margin-bottom: 0;
}
.filterbutton {
    grid-area: filterbutton;
    margin-bottom: 0;
    background-color:  var(--primary);
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    color: white;
}
.filterbutton:hover {
    cursor: pointer;
    background-color:  var(--hover);
    color: white;
}

.documentType {
    grid-area: type;
}
.clientCompany {
    grid-area: company;
}
.documentState {
    grid-area: state;
}
.dateFrom {
    grid-area: from;
}
.dateTo {
    grid-area: to;
}
.accountingYear {
    grid-area: year;
}

.form {
    background-color: var(--light);
    display: grid; align-items: center; gap: .75rem 1rem;
    margin-bottom: 0.75rem;
    padding: .75rem;
}
.formWithoutCompany {
    composes: form;
    grid:
            "type state"
            "from to"
            "year button"
            / 1fr 1fr;
}
@media (min-width: 768px) and (max-width: 1023px) {
    .formWithoutCompany {
        grid:
            "type state year"
            "from to button"
            / 1fr 1fr 1fr;
    }
}
@media (min-width: 1024px) {
    .formWithoutCompany {
        grid:
            "type state from to year button"
            / 2fr 2fr 2fr 2fr 2fr 1fr;
    }
}
.formWithoutState {
    composes: form;
    grid:
            "type company"
            "from to"
            "year button"
            / 1fr 1fr;
}
@media (min-width: 768px) and (max-width: 1023px) {
    .formWithoutState {
        grid:
            "type company year"
            "from to button"
            / 1fr 1fr 1fr;
    }
}
@media (min-width: 1024px) {
    .formWithoutState {
        grid:
            "type company from to year button"
            / 2fr 2fr 2fr 2fr 2fr 1fr;
    }
}
.fullForm {
    composes: form;
    z-index: 50;
    position:relative;
    grid:
            "type company state from to year filterbutton button "
            / 2fr 2fr 2fr 2fr 2fr 2fr 1fr 1fr;
}
/*
.fullForm {
    composes: form;
    grid:
            "type type type company company company"
            "state state state year year year"
            "from from to to button button"
            / 1fr 1fr 1fr 1fr 1fr 1fr;
}
@media (min-width: 768px) and (max-width: 1023px) {
    .fullForm {
        grid:
            "type type type type company company company company state state state state"
            "from from from to to to year year year button button button"
            / 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}
@media (min-width: 1024px) {
    .fullForm {
        grid:
            "type company state from to year filterbutton button "
            / 2fr 2fr 2fr 2fr 2fr 2fr 1fr 1fr;
    }
}*/
.highlightedField{
    
}
.highlightedFieldWrap{
    color: var(--purple);
    
}